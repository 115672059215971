<template>
  <b-container>
    <b-row class="mt-3">
        <b-col>
            <p style="font-size: 1.3em;"><b>Select your payment method</b></p> 
            <hr>
        </b-col>
    </b-row>
    <b-row v-if="!loading">
        <b-col>
            <template v-if="paidFor">Uspesno placeno</template>
            <!-- <button class="payCardBtn" v-if="card" @click="cardPay()">Pay</button> -->
            <div ref="paypal" style="margin: 20px;"></div>
            <!-- <button class="payPalBtn" v-if="paypal">PayPal</button> -->
        </b-col>
    </b-row>
    <b-row>
        
    </b-row>
    <b-row>
        <!-- <b-col cols="9"> -->
        <div  v-if="loading" class="d-flex justify-content-center mb-3 payPal">
            <b-spinner label="Loading..."></b-spinner>
        </div>
        <!-- </b-col> -->
    </b-row>
    <!-- <div v-if="loading" class="d-flex justify-content-center mb-3">
        <b-spinner label="Loading..."></b-spinner>
    </div> -->
  </b-container>
</template>

<script>
import axios from 'axios'
export default {
    components: {
    },
    data() {
        return {
            mainProps: { width: 28, height: 28, class: 'ml-2' },
            card: true,
            paypal: false,
            activeColor: '#e1f4f3',
            inactiveColor: 'white',
            //PayPal
            loaded: false,
            loading: false,
            paidFor: false,
            product: {
                price: 1.0,
                description: "Parking space reservation in period: "+this.$store.state.start.toLocaleString("DE-de")+"-"+this.$store.state.end.toLocaleString("DE-de"),
                img: "./assets/lamp.jpg"
            },
            url: 'http://goandpark.eu:8086/parking/',
            reservation: {}
        }
    },
    mounted: function() {
        const script = document.createElement("script");
        script.src =
        "https://www.paypal.com/sdk/js?client-id=AWxlw5GAwXSNH6COlaTTIllUQEC38OvNC4h-KmHowUcq51LqLiwTh7fEGCLq0maPDvvewWe8HZq2LVqq";
        script.addEventListener("load", this.setLoaded);
        document.body.appendChild(script);

        console.log("customer", this.$store.state.customer)
        this.makeReservation()
    },
    methods: {
        makeReservation(){
            let body = {}
            body.parkingSpace = this.$store.state.parkingSpace
            body.customer = this.$store.state.customer
            body.start = this.$store.state.start.getTime()
            body.end = this.$store.state.end.getTime()
            axios.post(this.url+'api/reserve', body)
                .then(result => {
                if(result.data.result === 'ok'){
                    this.product.price = result.data.reservation.price
                    this.reservation = result.data.reservation
                }else{
                    alert(result.data.reason)
                }
                
                })
                .catch(error => {
                console.log('GRESKA', error);
                })
        },
        confirmReservation(){
            this.loading = true
            axios.get(this.url+'api/confirmReservation?id='+this.reservation.id)
                .then(result => {
                    this.loading = false
                    if(result.data.result === 'ok'){
                        this.$router.push('successPayment?id='+this.reservation.id);
                    }
                })
                .catch(error => {
                console.log('GRESKA', error);
                })
        },
        cardBtn() {
            this.card = true;
            this.paypal = false;
            this.activeColor = '#e1f4f3';
            this.inactiveColor = 'white';           
        },
        paypalBtn() {
            this.card = false;
            this.paypal = true;
            this.activeColor = 'white';
            this.inactiveColor = '#e1f4f3';
        },
        cardPay() {
            this.$router.push('/successPayment') //samo za probu, kad plati treba da vodi ovde
        },
        //PayPal
        setLoaded: function() {
            this.loaded = true;
            // this.loading = true
            window.paypal
                .Buttons({
                createOrder: (data, actions) => {
                    return actions.order.create({
                    purchase_units: [{
                            description: this.product.description,
                            amount: {
                                currency_code: "USD",
                                value: this.product.price
                            }
                        },
                        
                    ],
                    application_context: {
                        shipping_preference: 'NO_SHIPPING',
                    }
                    });
                },
                onApprove: async (data, actions) => {
                    const order = await actions.order.capture();
                    
                    this.data;
                    this.paidFor = true;
                    if(this.paidFor) {
                        //posalji na server da je rezervacija uspesna
                        // this.$router.push('successPayment');
                        this.confirmReservation()
                    }
                    this.cardPay();
                    console.log(order, 'ovo se desiiiii');
                },
                onError: err => {
                    this.loading = false
                    console.log(err);
                }
                })
                .render(this.$refs.paypal);
        },
    }

}
</script>

<style scoped>
    .container {
        padding: 0;
    }

    .payCardBtn {
        margin-top: 5em;
        box-shadow: 0 3px 0 rgb(192 200 235 / 60%);
        width: 90%;
        border-radius: 8px;
        height: 3.5em;
        border-color: #17a2b8 !important;
        color: white;
        background-color: #17a2b8 !important;
    }
    .payPalBtn {
        margin-top: 10em;
        box-shadow: 0 3px 0 rgb(192 200 235 / 60%);
        width: 90%;
        border-radius: 8px;
        height: 3.5em;
        border-color: #17a2b8 !important;
        color: white;
        background-color: rgb(255,196,57) !important;  
    }

    .list-group-item {
        padding-left: 0; 
        padding-right: 0;
    }

    .payPal {
        margin: 15px !important;
    }
</style>

  