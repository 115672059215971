<template>
  <b-container>
    <b-row class="mt-1" v-if="showSuccessPayment">
        <b-col class="bold">
            <p>You have made a <span style="color: #118f40;">successful</span> payment!</p>
            <hr>
        </b-col>
    </b-row>
    <b-row class="mt-2">
        <!-- //TODO v-if dodati dinamicku promenljivu  -->
        <b-col class="bold" style="padding-bottom: 0px" v-if="this.days>0 || this.hours>0 || this.minutes>0">
            <p>
                Your parking space will be active in 
                <br>
                <template v-if="this.days>0">
                    <span class="time">
                        {{this.days}}
                    </span> 
                    day(s) 
                </template>
                <template v-if="this.days>0 || this.hours>0">
                    <span class="time">{{this.hours}}</span> hours 
                </template>
                <span class="time">{{this.minutes}}</span> minutes
            </p>
        </b-col>
        <b-col class="bold" style="padding-bottom: 0px" v-else>
            <p>
                If you are on location, you can open barrier now, or navigate to location.
            </p>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
             <b-button class="navigate" style="color: white;" @click="openLinkInNewTab('http://maps.google.com/maps?daddr='+lat+','+lng+'&amp;ll=')">
                <b>Navigate to location</b> <b-img :src="require('../assets/mapLogo.svg')" v-bind="mainProps" rounded alt="Rounded image"></b-img>
             </b-button>
        </b-col>
    </b-row>
    <b-row class="mb-3">
        <b-col>
            <b-button :class="(this.days>0 || this.hours>0 || this.minutes>0) ? 'unlockBtn disabled': 'unlockBtn'" @click="opneBarrier()" 
            >
                <b>OPEN BARRIER</b> 
            </b-button>
        </b-col>
    </b-row>
    
    <hr>
    <b-row>  
        <b-col>
            <p style=" margin: 0;"><span style="color: #C1001F;"><b>Remember:</b></span> You can not open barrier before  {{this.$store.state.start.toLocaleString("DE-de")}}</p>
        </b-col>
    </b-row>
    <hr>

    <b-row class="timeAndDate">
        <b-col cols="7" style="text-align: left">
            Date and time of arrival: 
        </b-col>
        <b-col style="padding-left: 0px; padding-right: 0px;">
            <b>{{this.$store.state.start.toLocaleString("DE-de")}}</b>
        </b-col>
    </b-row>
     <b-row>
        <b-col cols="7" style=" padding-right: 0px; text-align: left">
            Date and time of departure: 
        </b-col>
        <b-col style="padding-left: 0px; padding-right: 0px;">
            <b>{{this.$store.state.end.toLocaleString("DE-de")}}</b>
        </b-col>
    </b-row>
     <b-row>
        <b-col style="padding-top: 0px;">
            Parking time: <span style="font-size: 20px; font-weight: bold;">{{this.$store.state.diffMinutesHours}}</span> hour(s)
        </b-col>
    </b-row>
     <!-- <b-row>
        <b-col class="bodyText">
            
            <p>You can raise the parking barrier at the reserved parking space via the <b>link</b> that will reach you by <b>e-mail</b></p>
        </b-col>
    </b-row>
    <b-row>
        <b-col class="bodyText">
            <p><span style="font-size: 18px;">OR</span></p>
        </b-col>
    </b-row>
    <b-row>
        <b-col class="bodyText">
            <p>By clicking on the <b>button</b></p>
        </b-col>
    </b-row> -->
    
  </b-container>
</template>

<script>
import axios from 'axios'
import moment from 'moment';
    export default {
        data() {
            return {
                disabledBtn: false,
                activeColor: '#17a2b8',
                disableColor: 'red',
                borderColor1: '#17a2b8',
                currentTime: new Date(),
                arrivalTime: this.$store.state.start,
                days: '',
                hours: '',
                minutes: '',
                diffMinutes: '',
                mainProps: { width: 25, height: 25, class: 'm1' },
                lat: '',
                lng: '',
                reservation: {},
                url: 'http://goandpark.eu:8086/parking/',
                showSuccessPayment: true
            }
        },
        created() {
            this.loadData();
            this.btnColor();
            this.time();
        },
        
        methods: {
            loadData(){ 
                if(this.$route.query.id){
                    this.showSuccessPayment = false
                    this.getReservation()
                } else {
                    console.log(this.$store.state.parkingSpace);
                    this.lat = this.$store.state.parkingSpace.lat,
                    this.lng = this.$store.state.parkingSpace.lng
                    console.log(`*************lat: ${this.lat}, lng: ${this.lng}`);
                }
            },
            getReservation(){
                axios.get(this.url+'api/getReservation?id='+this.$route.query.id)
                        .then(result => {
                            if(result.data.result === 'ok'){
                                this.reservation = result.data.reservation
                                console.log("Reservation", this.reservation)
                                this.lat = this.reservation.parkingSpace.lat
                                this.lng = this.reservation.parkingSpace.lng
                                this.arrivalTime = new Date(this.reservation.startLong)
                                this.$store.state.start = this.arrivalTime
                                this.$store.state.end = new Date(this.reservation.endLong)

                                this.$store.state.diffMinutesHours = this.reservation.parkingTime
                            }
                        })
                        .catch(error => {
                        console.log('GRESKA', error);
                        })
            },
            openLinkInNewTab(link) {
              window.open(link, '_blank')
            },
            btnColor() {
                if(this.days > 0 || this.hours > 0 || this.minutes > 0) {
                    this.activeColor = '#ffb08a !important' ;
                    this.borderColor1 = '#ffb08a';
                }
            },
            time() {
                console.log('Trenutno vreme*****************', this.currentTime);
                console.log('Kad je rezervisano*************', this.$store.state.start);
                
                let date1 = moment(this.currentTime);
                let date2 = moment(this.$store.state.start);
                    
                let diffInMinutes = date2.diff(date1, 'minutes'); // !i ovde treba +1 .... ?!? mozda zbog sekundi, zapocne novi minut
                console.log('Razlika vremena********', diffInMinutes);
                this.calculateTime(diffInMinutes);         
            },
            calculateTime(time) {

                this.days = ((time / 60) / 24) | 0;
                console.log(`Broj dana ${this.days}`);
                
                if(this.days >= 1) {
                    this.hours = ((time - this.days * 24 * 60) / 60) | 0; 
                    console.log(`Broj sati ako je days >=1 ${this.hours}`);

                    this.minutes = time - (this.days * 24 * 60) -(this.hours * 60);
                    console.log(`Broj minuta 1 ${this.minutes}`);

                } else {
                    this.hours = (time / 60) | 0;
                    console.log(`Broj sati ako je days < 1 ${this.hours}`);

                    this.minutes = time - (this.hours * 60);
                    console.log(`Broj minuta 2 ${this.minutes}`);
                }
            },
            opneBarrier() {
                if(this.days > 0 || this.hours > 0 || this.minutes > 0) {
                    alert(`You can not open barrier before ${this.$store.state.start.toLocaleString("DE-de")}`)
                } else {
                    this.activeColor = '#ca6a3c';
                    console.log('otvori barijeru');
                    axios.get(this.url+'api/openBarrier?id='+this.reservation.id)
                        .then(result => {
                            if(result.data.result === 'ok'){
                                this.$notify.success({
                                    message: "Barrier opened"
                                })
                            }                     
                        })
                        .catch(error => {
                        console.log('GRESKA', error);
                        })
                }
            }
        }        
    }
</script>

<style scooped>
    .bold p {
        font-size: 19px;
        font-weight: bold; 
    }

    .time {
        font-weight: bold; 
        font-size: 27px;
        color: #17a2b8; 
    }

    .unlockBtn {
        width: 70%;
        height: 6.1em;
        border-color: #ca6a3c !important;
        color: white !important;
        background-color: #ca6a3c !important;
        border-radius: 6em !important;

        -webkit-box-shadow: 0px 9px 0px rgb(194, 178, 175), 0px 9px 25px rgba(0, 0, 0, .7);
        -moz-box-shadow: 0px 9px 0px rgba(224,137,92, 1), 0px 9px 25px rgba(0, 0, 0, .7);
        box-shadow: 0px 9px 0px rgba(224,137,92, 1), 0px 9px 25px rgba(0, 0, 0, .7);
    }

    .disabled {
        background-color: #e4beac !important;
         border-color: #e4beac !important;
    }

    .unlockBtn:hover {
        box-shadow: none;
        top: 6px;
        /* background-color: #3e8e41;
        box-shadow: 0 5px #666;
        transform: translateY(4px); */
    }
    .unlockBtn:active {
        background-color: #3e8e41;
        box-shadow: 0 5px #666;
        transform: translateY(2px);
    }

    .bodyText {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .bodyText p {
        margin-bottom: 8px;
    }

    hr {
       border-top: 1px solid rgba(193 0 31 0.3) !important; 
    }

    .navigate {
        color: #ca6a3c !important;
        background-color: white !important;
        border: 1px solid #c0c8eb !important;
        box-shadow: 0 3px 0 rgb(192 200 235 / 60%);
    }

    /* .timeAndDate col{
        padding: 0;
    } */
</style>  