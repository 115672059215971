import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueGeolocation from 'vue-browser-geolocation';
import * as VueGoogleMaps from 'vue2-google-maps';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);


Vue.config.productionTip = false
Vue.use(VueGeolocation);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Vuetify);


Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDtG2d9h8rjKmdWbyxPYPdNzq5i7szV3Mc',
    libraries: 'places', // This is required if you use the Autocomplete plugin
  }
})



new Vue({
  router,
  store,
  vuetify: new Vuetify(),
  render: h => h(App)
}).$mount('#app')
