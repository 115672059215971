<template>
  <b-container justify-content-center>
    <b-row>
      <b-col style="padding-bottom: 0">
        <div class="divBack">
          <button @click="back" class="icon-back"></button>
        </div>
        <h4>Log In</h4>
        <hr>
      </b-col>
    </b-row>
    <b-row>
      <b-col >
        <p style="">Enter your email and password</p>
      </b-col>
    </b-row>
      <b-form @submit.prevent="onSubmit" v-if="show">
        <b-row>
          <b-col>
            <b-form-group
              id="input-group-4"
              label-for="input-4"
            >
                <b-form-input
                    id="input-4"
                    v-model="form.email"
                    type="email"
                    placeholder="Enter email"
                    required
                ></b-form-input>
            </b-form-group>
          </b-col>    
        </b-row>   
        <b-row>
          <b-col style="text-align: right;">
            <b-form-group id="input-group-6" label-for="input-6">
              <b-form-input
                id="input-6"
                v-model="form.password"
                type="password"
                placeholder="Enter password"
                required
              ></b-form-input>
            </b-form-group>
            <span class="forgotPassw"><b-link href="#signup" @click="resetPasswprd">Forgot your password?</b-link></span>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-button type="submit" class="signUpBtn" >Submit</b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <p>Not yet registered? <router-link to="/signup">Sign up here</router-link></p>
          </b-col>
        </b-row>
      </b-form>
  </b-container> 
</template>

<script>
import axios from 'axios';
import VueJwtDecode from "vue-jwt-decode";
  export default {
    data() {
      return {
        form: {
          email: '',
          password: '',
        },
        apiUrl: 'https://parking-reservation/user-controler',
        accessToken: 'tokenString',
        show: true,
        url: 'http://goandpark.eu:8086/parking/'
 
      }
    },
    methods: {
      async onSubmit() {
        //* 1. NACIN za slanje tokena ali nije pametno, jer ce svima slati token
        // axios.interceptors.request.use(
        //   config => {
        //     config.headers.authorization = `Bearer ${this.accessToken}`;
        //     return config;
        //   },
        //   error => {
        //     return Promise.reject(error);
        //   }
        // )

        //* 2. NACIN za slanje tokena, bolja praksa
        // const authAxios = axios.create({
        //   baseURL: this.apiUrl, 
        //   ehaders: {
        //     Authorization: `Bearer ${this.accessToken}`
        //   }
        // })

        // const user = this.form;
        
        // authAxios.get('/users/all', user).then(res => {
        //   console.log(res);
        //   this.$router.push('/');
        // }).catch(err => {
        //   console.log(err);
        // })
        let userLogin = this.form;
        axios.post(`${this.url}/user/login`, userLogin).then(res => {
          console.log(res);
          console.log(res.status);
          if(res.data.result === "ok") {
            let token = res.data.token;
            console.log(token, 'ovo je token');
            localStorage.setItem("userToken", token);
            this.$router.push("/");
          }
        }).catch(err => {
          console.log(err.message);
        }) 

        // alert(JSON.stringify(this.form))
      },
      resetPasswprd() {
        this.$router.push('/request-password');
      },
      back() {
        this.$router.push('/');
      },
      //ne treba da bude ovde
      getUserDetails() {
        let token = localStorage.getItem("user");
        try {
          let decoded = VueJwtDecode.decode(token);
          this.user = decoded;
        } catch (error) {
          console.log(error, 'error from decoding token');
        }
      },
      //ne treba da bude ovde
      logUSerOut() {
        localStorage.removeItem("user");
        this.$router.push('/');
      }
    },
    created() {
      this.getUserDetails();
    }
  }
</script>
<style scoped>
  .container {
    padding-left: 24px;
    padding-right: 24px;
  }

  .icon-back {
    background-image: url('../assets/back.png');
    width: 24px;
    height: 24px;
    background-size: 16px;
    background-repeat: no-repeat;
    vertical-align: middle;
    margin-top: 0.37em;
  }

  .divBack {
    background-color: #C1001F;
    text-align: center;
    width: 2.5em;
    height: 2.5em;
    border-radius: 50%;
  }

  h4 {
    margin-bottom: 0;
    color: #C1001F;
  }

  P {
    font-size: 1em; 
    color: rgba(23,24,53,.6);
  }

  input {
    padding: 14px 20px 14px 16px;
    height: 50px;
    margin: 0;
  }

  .form-group {
    margin: 0;
  }

  .col {
    height: auto;
  }

  .input-group-7{
    text-align: left;
  }

  .forgotPassw {
    font-size: 0.8rem;  
    color: #C1001F;  
  }

  input[type="password"] {
    margin-bottom: 1rem;
  }

  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #C1001F;
    outline: 0;
    border-width: 2px;
    box-shadow: 0 0 0 0.2rem rgb(193 0 31 / 1%) !important;
  }

  .checkBox {
    color: rgba(23,24,53,.6);
    font-size: 0.75rem; 
    letter-spacing: 0.3px;
  }

  .signUpBtn {
    width: 100%;
    height: 3em;
    border-color: #C1001F !important;
    color: white;
    background-color: #C1001F !important;
  }

  .signUpBtn:active {
    transform: scale(0.98);
  }

  a {
    color: #C1001F !important;
  }

</style>