<template>
  <b-container>
      <b-row>
          <b-col>
              How it works. . . 
          </b-col>
      </b-row>
  </b-container>
</template>

<script>
export default {

}
</script>

<style>

</style>