<template>
  <v-app>
  <b-container fluid > 
    <!-- //! *****************************************************Serach Full width*****************************************-->
    <b-row id="searchFilter"  align-h="center" v-if="!mobile">
      <b-col lg="4" class="heightCol">
          <VueGoogleAutocomplete
            class="addressInput"
            v-if="mapLoaded"
            id="map"
            ref="address"
            classname="form-control"
            placeholder="Enter address"
            v-on:placechanged="getAddressData"
          >
        </VueGoogleAutocomplete>
      </b-col>
      
      <b-col lg="2" class="heightCol">
        <VueCtkDateTimePicker color="firebrick" label='ARRIVAL' v-model="datetimeArrival" />
      </b-col>
      <b-col lg="2" class="heightCol">
        <VueCtkDateTimePicker color="firebrick" label='DEPARTURE' v-model="datetimeDeparture" />
      </b-col>

      <b-col lg="1" style="height: 79px">
        <b-button @click="search()" style="background-color: #17a2b8;" id="searchBtn"><b-icon icon="search"></b-icon></b-button>
      </b-col>

    </b-row>

  <!-- //! *******************************************************Serach Mobile*********************************************-->
    <b-row id="searchFilterMobile"  align-h="center" v-if="mobile && showMap" @click="searchDetailsMobile()">
      <b-col cols="10" class="colMobile">
        <b-container>
          <b-row>
            <b-col style="padding: 1px 0 3px 0;">
              <h4 style="font-size: 12px; font-weight: bold; margin-bottom: 0;">{{this.fullNameAddress}}</h4>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="dateTimeMobile" style="font-size: 12px; padding: 0 0 3px 0; text-align: center">
              <span>{{this.$store.state.start.toLocaleString("DE-de") }}</span>    
              <!-- <span>{{this.$store.state.displayArrivalTime}}</span>      -->
              <span>&rarr;</span>
              <span style="margin-right: 0;">{{this.$store.state.end.toLocaleString("DE-de")}}</span>
              <!-- <span id="lastSpan">{{this.$store.state.displayDepartureTime}}</span>  -->
            </b-col>
          </b-row>
          <b-row>
            <b-col style="font-size: 12px; padding: 0 0 3px 0; font-weight: bold;"> 
              {{this.$store.state.diffMinutesHours}} hours
            </b-col>
          </b-row>
        </b-container>
      </b-col>
      
      <b-col cols="2" class="colMobile colMobileBtn"  >
        <b-button @click="search()" style=";" id="searchBtnMobile"><b-icon icon="search"></b-icon></b-button>
      </b-col>

    </b-row>

    <!-- //!---------------------------------------------------- MOBILE SEARCH DETAILS ---------------------------------------->
      <template v-if="searchDetailsView && mobile">
        <b-row >
          <b-col>
            <h4 class="mt-4 mb-4 detailsText">Pick a location</h4>
          </b-col>
        </b-row>
        <b-row align-h="center">
          <b-col cols="11">
            <VueGoogleAutocomplete
              class="addressInput"
              v-if="mapLoaded"
              id="map"
              ref="address"
              classname="form-control"
              placeholder="Enter address"
              v-on:placechanged="getAddressData"
            >
            </VueGoogleAutocomplete>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" style="padding-right: 0px; padding-left: 0px;">
            <hr class="mt-4 mb-4">
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h4 class="detailsText">Pick a date</h4>
          </b-col>
        </b-row>
        <b-row align-h="center" >
          <b-col cols="10" class="mt-3">
            <VueCtkDateTimePicker color="firebrick" label='ARRIVAL' v-model="datetimeArrival" />
          </b-col>
        </b-row>
        <b-row align-h="center" class="mt-3 mb-3">
          <b-col cols="10">
             <VueCtkDateTimePicker color="firebrick" label='DEPARTURE' v-model="datetimeDeparture" />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <!-- {{this.$store.state.diffMinutesHours}} Hour -->
          </b-col>
        </b-row>
        <b-row>
          <b-col>
             <b-button @click="search()" id="serachMobileTemlate" class="mt-4">SEARCH</b-button>
          </b-col>
        </b-row>
      </template>

    <!-- //! ------------------------------------------------------------ MAP ------------------------------------------------->
    <div :style="showMap ? '' : 'display: none'">
      <GmapMap
        ref="mapRef" 
        :center="center"
        :zoom="mapZoom"
        :options="{ gestureHandling: 'greedy'}"
        style="width: 604px, height: 360px;"
      >
        <GmapMarker
          :key="index"
          v-for="(m, index) in parkingSpaces"
          :position="m"
          :label="'P'"
          :clickable="true"
          :draggable="true"
          @click="openWindow(m)"
        />
        <GmapInfoWindow 
              @closeclick="window_open=false" 
              :opened="window_open"
              :options='test'
              :position="selectedLocation" 
              
          >
              <div id="content"> 
                <!-- <h4 id="firstHeading" class="firstHeading">Parking reservation</h4>  -->
                <div id="bodyContent">
                  <h4>{{selectedLocation.name}}</h4> 
                  <p style="font-size: 15px;">{{fullNameAddress}}</p>
                  <button @click="chooseSpace()" style="background-color: #C1001F; width: 200px; height: 30px;color: white;">RESERVATION</button> 
                </div> 
            </div>`
          </GmapInfoWindow>  
        </GmapMap>
      </div>

      <!-- //!-------------------------------------------------- RESERVATON DETAILS - PIN --------------------------------->
      <template v-if="showReservationDetails">
        <Payment v-bind:selectedLocation="selectedLocation" v-bind:fullNameAddress="fullNameAddress" @cancelBtn="handleCancel($event)"/>
        <!-- <button @click="cancel()" id="cancel" style="">CANCEL</button>  -->
      </template>
      <!-- icon="http://maps.google.com/mapfiles/ms/icons/orange-dot.png"  IKONICA ZA MARKER-->
  </b-container>
  </v-app>
</template>

<script>
import VueGoogleAutocomplete from '@/components/vue-google-autocomplete'
import moment from 'moment'
import Payment from './Payment.vue';
// import datetime from 'vuejs-datetimepicker';

const work = {lat: 44.722771, lng: 20.431947};
const random = {lat: 44.728500, lng: 20.441614};
const axios = require('axios');

export default {
  name: 'Map',
  components:{
    VueGoogleAutocomplete,
    Payment,

  },
  props: {
    msg: String
  },
  data(){
    return {
      //DISPLAY
      fullWidth: false,
      mobile: false,
      searchDetailsView: true,
      showMap: false,
      showReservationDetails: false ,
      //DATE
      datetimeArrival: new Date(),
      datetimeDeparture: new Date(),
      //MAP
      mapLoaded: false,
      address: null,
      fullNameAddress: '',
      pickedLocation: null,
      window_open: false,
      enteredAddress: '',
      pickedCoordinates: null,
      autocomplete: '',
      markers: [
        {
          label: "P", 
          position: work,
          id: '1',
          name: 'Parking 1'
        }, 
        {
          label: "P",
          position: random,
          id: '2',
          name: 'Parking 2'
        }
      ],
      parkingSpaces: [],
      center: work,
      selectedLocation: {},
      seeReservation: false,
      coordinates: {
        lat: 0,
        lng: 0
      },
      test: {
        pixelOffset: {
          height:-35,
          width:0
        }
      } ,
      mapZoom: 18,
      event: [],
      url: 'http://goandpark.eu:8086/parking/'
    }
    
  },
  computed:{
    timeOpts:() => {
      return {
          format: "24hr"
      }
    },
    // timeChange:() => {
    //   `this` points to the vm instance
    //   return this.$store.state.diffMinutesHours
    // }
  },
  mounted() {
    // this.$refs.address.focus()
    // this.initAutocomplete()
    //problem ne ucita podatke sa googla koje treba
    this.$refs.mapRef.$mapPromise.then((map) => {
      console.log('map', map);
      map.panTo({lat: work.lat, lng: work.lng})
      var self = this;
      setTimeout(function() {
            self.mapLoaded = true
      }, 100);
      
      console.log('Mounted se des .....');
    })
    this.getParkingSpaces()
  },
  methods: {
    getParkingSpaces(){
      axios.get(this.url+'api/getParkingSpaces')
        .then(result => {
          if(result.data.result === 'ok'){
            this.parkingSpaces = result.data.spaces
          }
          
        })
        .catch(error => {
          console.log('GRESKA', error);
        })
    },
    loadedData(){
      alert("dsadas")
    },
    getAddressData (addressData, placeResultData, id) {
      this.address = addressData; //ovde je objekat adrese koja se ukuca u pretrazivanje
      this.fullNameAddress = placeResultData.formatted_address; //puno ime adrese, iz istog objekta adrese samo drugacije formatiranog

      console.log('ovo je address', this.address);
      console.log('heloooooo',placeResultData, id);

      // this.getAddress(this.address, this.fullNameAddress);

      // this.address.latitude
      // this.address.longitude
      
      

    },
    openWindow (location) {
      this.window_open = true
      this.selectedLocation = location
      this.$store.state.parkingSpace = location;
    },
    chooseSpace() {
      this.showReservationDetails = true
      this.showMap = false;
      console.log('unesite podatke za rezervaciju');
    },
    getAddress() {
      axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
        params: {
          address: this.fullNameAddress,
          key: 'AIzaSyDtG2d9h8rjKmdWbyxPYPdNzq5i7szV3Mc'
        }
      })
        .then(result => {
          console.log("RESULT: "+result.data.toString());
          this.pickedLocation = result.data.results[0];

          
          console.log('odg Google mapa', this.pickedLocation);
          this.pickedCoordinates = this.pickedLocation.geometry.location;

          console.log('kordinate izabrane adrese',this.pickedCoordinates);
          this.$refs.mapRef.$mapPromise.then((map) => {
            console.log('ovo je map zoom', map.zoom);
            map.zoom = 18;
            map.panTo({lat: this.pickedCoordinates.lat, lng: this.pickedCoordinates.lng})
          })
          //da nadje na mapi
          // this.$getLocation({})
          // .then(coordinates => {
          //   console.log('moje koordinate',coordinates);
            
          // })
          // .catch(error => alert(error));
          // treba da stavim sve promenljive da prebacim u vuex u 
          
        })
        .catch(error => {
          console.log('GRESKA', error);
        })
    },
    search() {
      if(this.address == null) {
        return alert('please entered address'); //todo treba bolje resiti, da pise ispod inupta, npr..
      }
      this.getAddress();
      
      // this.$store.state.start = this.datetimeArrival; //treba mi zbog racunanja za koliko je parking slobodan (kad se uspesno plati, na toj str)

      this.$store.state.start = this.datetimeArrival;//moment(this.datetimeArrival.toLocaleDateString()).format('DD/MM/YYYY');
      this.$store.state.end = this.datetimeDeparture;//moment(this.datetimeDeparture.toLocaleDateString()).format('DD/MM/YYYY')

      // this.$store.state.displayArrivalTime = moment(this.datetimeArrival).format('HH:mm');
      // this.$store.state.displayDepartureTime = moment(this.datetimeDeparture).format('HH:mm')

      //racunanje koliko je sati izabrano za placanje
      let date1 = moment(this.datetimeArrival);
      let date2 = moment(this.datetimeDeparture);
          
      let diffInMinutes = date2.diff(date1, 'minutes');
      this.$store.state.diffMinutesHours = (diffInMinutes ) / 60; //delim sa 60 jer mi treba u satima koliko je to

      // 1.23 sata = 2 sata, ako ima ostatak zaokruzi na veci broj
      // if(((diffInMinutes + 1) / 60) % 60 != 0) {
      //   this.$store.state.diffMinutesHours += 1;
      //   this.$store.state.diffMinutesHours = this.$store.state.diffMinutesHours | 0;         
      // }
      // todo RESITI JEDAN MINUT KOJI JE PROBLEM ! !
      if(diffInMinutes % 60 != 0) {
        this.$store.state.diffMinutesHours += 1;
        this.$store.state.diffMinutesHours = this.$store.state.diffMinutesHours | 0;         
      }

      console.log('Diff in minutes:', diffInMinutes  ); // ! ne znam zasto jedan minut ne vidi ? ! ? ne radcuna prvi minut iz nekog razloga
        console.log('ovo je u satima 2 *********', this.$store.state.diffMinutesHours);
      /* // todo resiti kod desktop prikaza da se ovo ne poziva */
      this.searchDetailsView = false;
      this.showMap = true;
      // this.mobile = true;
    },
    cancel() {
      this.showReservationDetails = false
      this.showMap = true
    },
    searchDetailsMobile() {
      this.searchDetailsView = true;
      this.showMap = false;
      // this.mobile = false;
      console.log('cao');
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        console.log("Mobile")
        this.mobile = true
        // alert(this.mobile)
        return true
      } else {
        this.mobile = false
        console.log("Nije mobile")
        // alert(this.mobile)
        return false
      }
    },
      handleCancel(e) {
        this.showReservationDetails = e[0];
        this.showMap = e[1];
      }
  },
  created() {
    this.isMobile()
    console.log('created . . . .. ');
    console.log(this.datetimeArrival);
    this.datetimeDeparture.setHours(this.datetimeDeparture.getHours() + 1); //da bi pocetno vreme povecali za 1 sat
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .vue-map-container,
  .vue-map-container .vue-map {
      width: 100% !important;
      height: calc(100vh - 128px) !important;
      height: -webkit-fill-available;
  }

  .container-fluid {
    padding: 0;  
  }

  /* // TODO ovo treba staviti kada je ekran veliki, koristiti media kverije ili videti vec u bootstrap-u neko resenje
  .navbar .navbar-dark .bg-info .navbar-expand-lg{
    padding-left: 250px;
    padding-rigth: 250px;
  } */

  #searchFilter {
    padding: 8px;    
    margin: 0;
    background-color: #f2f7f7;
  }

  .heightCol {
    height: 53px;
  }
  
  .addressInput {
    width: 100%;
    height: 55px;
    border: 1px solid #C1001F;
  }
/* 
  input[data-v-4bd11526]{
    height: 58px !important;
    border: 1px solid #17a2b8;
  }

  .year-month-wrapper[data-v-4bd11526] {
    background-color: #17a2b8 !important;
  } */

  #searchBtn{
    height: 55px;
    width: 69px;
    color: white;
  }

  #searchBtn:hover {
    -moz-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
  }

  #searchBtn:active,  {
    transform: scale(0.98);
  }

  #cancel {
    background-color: blue; 
    width: 200px;
     height: 30px;
     color: white;
  }

  /* mobile */
  .heightColMobile {
    
  }

  .colMobileBtn {
    text-align: right;
  }
/* 
  #searchBtnMobile{
    text-align: center;
    height: 72px;
    width: 40px;
    color: #17a2b8;
    padding: 0px;
    background-color: white;
    border: 1px solid #17a2b8;
  } */

  #serachMobileTemlate:active {
    transform: scale(0.98);
  }

  #searchFilterMobile {
    padding-left: 8px;    
    height: 72px;
    margin: 0;
    background-color: #f2f7f7;
    box-shadow: 0 5px 5px -5px #333;
    margin-bottom: 2px;
  }

  .colMobile {
    padding: 0;
  }

  /* .dateTimeMobile span {
    margin-right: 0.6em;
    letter-spacing: 0.08em;
  } */

  #lastSpan {
    margin-right: 0;
  }

  #serachMobileTemlate {
    width: 90%;
    height: 3.4em;
    color: white;
    border-color: #C1001F;
    background-color: #C1001F;
  }

  #serachMobileTemlate:active {
    background-color: #C1001F;
  }

  .addressInput[data-v-3074bd5c] {
    border: 1px solid #C1001F ;
  }

  .form-control:focus {
    border-color: #C1001F !important;
    box-shadow: 0 0 0 0.2rem rgb(193 0 31 / 25%) !important;
  }

  .detailsText {
    font-size: 25px;
    font-weight: 900;
  }


</style>