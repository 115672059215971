<template>
  <b-container justify-content-center>
    <b-row>
      <b-col>
        <div class="divBack">
          <button @click="back" class="icon-back"></button>
        </div>
        <h4>Sign Up</h4>
        <hr>
      </b-col>
    </b-row>
    <!-- <b-row>
      <b-col>
        <p style="">Enter your details to create an account.</p>
      </b-col>
    </b-row> -->
      <b-form @submit.prevent="onSubmit" v-if="show">

        <!-- //* da ispise greske, bolje stilizovati 
        <b-row>
          <b-col>
            <p v-if="errors.length">
            <b>Please correct the following error(s):</b>
            <ul>
              <li v-for="error in errors">{{ error }}</li>
            </ul>
          </p>
          </b-col>
        </b-row> -->

        <b-row>
          <b-col cols="6" >
            <b-form-group
              id="input-group-1"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                v-model="form.name"
                type="text"
                placeholder="Name"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              id="input-group-2"
              label-for="input-2"
            >
              <b-form-input
                id="input-2"
                v-model="form.lastName"
                type="text"
                placeholder="Last name(s)"
                required
              ></b-form-input>
            </b-form-group >
          </b-col>
        </b-row>
      
        <b-row >
          <b-col>
            <b-form-group
              id="input-group-4"
              label-for="input-4"
            >
              <b-form-input
                id="input-4"
                v-model="form.address"
                type="text"
                placeholder="Adress"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>    
        </b-row>

        <b-row >
          <b-col>
            <b-form-group
              id="input-group-4"
              label-for="input-4"
            >
              <b-form-input
                id="input-4"
                v-model="form.phone"
                type="text"
                placeholder="Phone"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>    
        </b-row>

        <b-row >
          <b-col>
            <b-form-group
              id="input-group-4"
              label-for="input-4"
              description="We'll never share your email with anyone else."
            >
              <b-form-input
                id="input-4"
                v-model="form.email"
                type="email"
                placeholder="Email"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>    
        </b-row>
        
        <b-row style="text-align: left;">
          <b-col>
            <b-form-group id="input-group-6" label-for="input-6">
              <b-form-input
                id="input-6"
                v-model="form.password"
                type="password"
                placeholder="Password"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
         <b-row style="text-align: left;"> 
          <b-col>
            <b-form-group id="input-group-5" label-for="input-5">
              <b-form-input
                id="input-5"
                v-model="form.passwordConfirm"
                type="password"
                placeholder="Confirm password"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        
        <b-row>
          <b-col>
            <b-form-group id="input-group-7" v-slot="{ ariaDescribedby }">
              <b-form-checkbox-group
                style="text-align: left;"
                v-model="form.checked"
                id="checkboxes-7"
                :aria-describedby="ariaDescribedby"
              >
                <b-form-checkbox class="checkBox" value="me">I accept the <b-link href="#" >terms and conditions </b-link></b-form-checkbox>
              <!-- <b-form-checkbox style="font-size: 0.75rem; letter-sapcing: 0.3px" value="that">Something else, (receive news)</b-form-checkbox> -->
              </b-form-checkbox-group>
            </b-form-group>  
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-button type="submit" class="signUpBtn" >Submit</b-button>
          </b-col>
        </b-row>

        <b-row class="mb-2">
          <b-col cols="12">
            <p> Already have an account? <router-link to="/login" >Log in here</router-link></p>
          </b-col>
        </b-row>
      </b-form>
  </b-container> 
</template>

<script>
const axios = require('axios');

  export default {
    data() {
      return {
        form: {
          name: '',
          lastName: '',
          email: '',
          address: '',
          phone: '',
          password: '',
          passwordConfirm: '',
          // food: null,
          checked: [],
          errors: []
        },
        foods: [{ text: 'Select One', value: null }, 'Carrots', 'Beans', 'Tomatoes', 'Corn'],
        show: true,
        url: 'http://goandpark.eu:8086/parking/'
      }
    },
    methods: {
      onSubmit() {
        // if (this.name && this.age) {
        //   return true;
        // }

        // this.errors = [];

        // if (!this.name) {
        //   this.errors.push('Name required.');
        // }
        // if (!this.age) {
        //   this.errors.push('Age required.');
        // }
        
        let user = this.form;
        console.log(user);
        axios.post(this.url + 'user/insertUser', user).then(res => {
          console.log(res);
          if (res.data.status === 'ok') {
            this.$router.push('/login');  
          }
          
        }).catch(err => {

          console.log(err)
        })
          
        this.form ={}
      },
      back() {
        this.$router.push('/');
      }
      // onReset(event) {
      //   event.preventDefault()
      //   // Reset our form values
      //   this.form.email = ''
      //   this.form.name = ''
      //   this.form.food = null
      //   this.form.checked = []
      //   // Trick to reset/clear native browser form validation state
      //   this.show = false
      //   this.$nextTick(() => {
      //     this.show = true
      //   })
      // }
    }
  }
</script>
<style scoped>
  .container {
    padding-left: 24px;
    padding-right: 24px;
  }

  .icon-back {
    background-image: url('../assets/back.png');
    width: 24px;
    height: 24px;
    background-size: 16px;
    background-repeat: no-repeat;
    vertical-align: middle;
    margin-top: 0.37em;
  }

  .divBack {
    background-color: #C1001F;
    text-align: center;
    width: 2.5em;
    height: 2.5em;
    border-radius: 50%;
  }

  h4 {
    margin-bottom: 0;
    color: #C1001F;
  }

  P {
    font-size: 1em; 
    color: rgba(23,24,53,.6);
  }

  input {
    padding: 14px 20px 14px 16px;
    height: 50px;
    margin: 0;
  }

  .form-group {
    margin: 0;
  }

  .col {
    height: auto;
  }

  .input-group-7{
    text-align: left;
  }
  .checkBox {
    color: rgba(23,24,53,.6);
    font-size: 0.75rem; 
    letter-sapcing: 0.3px;
}

  .signUpBtn {
    width: 100%;
    height: 3em;
    border-color: #C1001F !important;
    color: white;
    background-color: #C1001F !important;
  }

  .signUpBtn:active {
    transform: scale(0.98);
  }

  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #C1001F;
    outline: 0;
    border-width: 2px;
    box-shadow: 0 0 0 0.2rem rgb(193 0 31 / 1%) !important;
    }

  a {
    color: #C1001F !important;
  }

  hr {
    color: #C1001F;
       border-top: 1px solid rgba(193 0 31 0.3) !important; 
    }
  
</style>