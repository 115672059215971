<template>
  <div id="app">
     <b-navbar toggleable="lg" type="dark" style="background-color: #000;">
      <b-navbar-brand href="#" >
        <b-link to="/" class="text-decoration-none;">
           <b-img :src="require('../src/assets/GoPark-logo.png')" class="logo-image" alt="logo"></b-img>
        </b-link>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item href="#">How it works</b-nav-item>
          <b-nav-item href="#">Help</b-nav-item>
          <b-nav-item href="#">Contact</b-nav-item> 
        </b-navbar-nav> 

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item href="#" v-if="!isLoggin"><b-link to="/login" class="text-decoration-none">Log in</b-link></b-nav-item> 
          <b-nav-item href="#" v-if="!isLoggin"><b-link to="/signup" class="text-decoration-none">Sign up</b-link></b-nav-item> 
          <b-nav-item href="#" v-else><b-link to="/signup" class="text-decoration-none">Log out</b-link></b-nav-item> 
          <!-- <b-nav-item-dropdown text="Lang" right>
            <b-dropdown-item href="#">EN</b-dropdown-item>
            <b-dropdown-item href="#">ES</b-dropdown-item>
            <b-dropdown-item href="#">RU</b-dropdown-item>
            <b-dropdown-item href="#">FA</b-dropdown-item>
          </b-nav-item-dropdown> -->
        </b-navbar-nav>

      </b-collapse>
    </b-navbar>
    <router-view/>
  </div>
</template>
<script>
export default {
  data() {
    return {
      //ovo treba iz vuex-a da procitam
      isLoggin: false
    }
  }
}
</script>
<style>
  body {
    margin: 0;
  }
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }

  #nav {
    padding: 5px;
    height: 50px;
  }

  #nav a {
    font-weight: bold;
    color: rgba(255, 255, 255, 0.5);
  }

  .nav-item a {
    color: rgba(255, 255, 255, 0.5);
  }

  #nav a.router-link-exact-active {
    color: #42b983;
  }

  .logo-image {
    width: 100%;
    height: 1.7em;
  }
</style>
