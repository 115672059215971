import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Login from '../components/Login.vue';
import Signup from '../components/Signup.vue';
import Profile from '../components/Profile.vue';
import Payment from '../components/Payment.vue';
import PaymentMethod from '../components/PaymentMethod.vue';
import SuccessPayment from '../components/SuccessPayment.vue';
import ResetPassword from '../components/ResetPassword.vue';
import Help from '../components/Help.vue';
import HowItWorks from '../components/HowItWorks.vue';
import Contact from '../components/Contact.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/login',
    name: Login,
    component: Login
  },
  {
    path: '/signup',
    name: Signup,
    component: Signup
  },
  {
    path: '/profile',
    name: Profile,
    component: Profile
  },
  {
    path: '/payment',
    name: 'Payment',
    component: Payment,
  },
  {
    path: '/paymentMethod',
    name: 'PaymentMethod',
    component: PaymentMethod
  },
  {
    path: '/successPayment',
    name: 'SuccessPayment',
    component: SuccessPayment
  },
  {
    path: '/request-password',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/help',
    name: 'Help',
    component: Help
  },
  {
    path: '/how-it-works',
    name: 'HowItWorks',
    component: HowItWorks
  },

  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },

]

const router = new VueRouter({
  routes
})

export default router
