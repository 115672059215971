<template>
    <b-row>
        <b-col>
            Contact. . . 
        </b-col>
    </b-row>
</template>
<script>
export default {
    data() {
        return {

        }
    }, methids: {

    }
}
</script>
<style scoped>

</style>