import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    //format date and time ready for display
    displayArrivalDate: '',
    displayDepartureDate: '',
    displayArrivalTime: '',
    displayDepartureTime: '',
    diffMinutesHours: '',
    //date object
    dateTimeArrival: new Date(),
    googleLocation: {},
    parkingSpace: {},
    start: null,
    end: null,
    customer: {}
  },
  mutations: {
    CHANGE_PARKING_SPACE(state, payload) {
      state.parkingSpace = payload
    },
    CHANGE_START(state, payload) {
      state.start = payload
    },
    CHANGE_END(state, payload) {
      state.end = payload
    },
    CHANGE_CUSTOMER(state, payload) {
      state.customer = payload
    },
    CHANGE_ARRIVAL_DATE(state, payload) {
      state.displayArrivalDate = payload
    },
    CHANGE_DEPARTURE_DATE(state, payload) {
      state.displayDepartureDate = payload
    },
    CHANGE_ARRIVAL_TIME(state, payload) {
      state.displayArrivalTime = payload
    },
    CHANGE_DEPARTURE_TIME(state, payload) {
      state.displayDepartureTime = payload
    },
    CHANGE_DIFF_MINUTES_HOURS(state, payload) {
      state.diffMinutesHours = payload
    },
    CHANGE_DATE_TIME_ARRIVAL(state, payload) {
      state.dateTimeArrival = payload
    },
    CHANGE_GOOGLE_LOCATION(state, payload) {
      state.googleLocation = payload
    }
  },
  actions: {
    changeParkingSpace(store, payload) {
      store.commit('CHANGE_PARKING_SPACE', payload)
    },
    changeStart(store, payload) {
      store.commit('CHANGE_START', payload)
    },
    changeEnd(store, payload) {
      store.commit('CHANGE_END', payload)
    },
    changeCustomer(store, payload) {
      store.commit('CHANGE_CUSTOMER', payload)
    },

    changeArrivalDate(store, payload) {
      store.commit('CHANGE_ARRIVAL_DATE', payload)
    },
    changeDepartureDate(store, payload) {
      store.commit('CHANGE_DEPARTURE_DATE', payload)
    },
    changeArrivalTime(store, payload) {
      store.commit('CHANGE_ARRIVAL_TIME', payload)
    },
    changeDepartureTime(store, payload) {
      store.commit('CHANGE_DEPARTURE_TIME', payload)
    },
    changeDiffMinutesHours(store, payload) {
      store.commit('CHANGE_DIFF_MINUTES_HOURS', payload)
    },
    changeDateTimeArrival(store, payload) {
      store.commit('CHANGE_DATE_TIME_ARRIVAL', payload)
    },
    changeGoogleLocation(store, payload) {
      store.commit('CHANGE_GOOGLE_LOCATION(', payload)
    }
  },
  modules: {
  }
})
