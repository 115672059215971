<template>
  <b-container justify-content-center>
    <b-row class="mt-1">
      <!-- <b-col cols="2" >
        <b-button @click="cancel()" id="backBtn">&laquo;</b-button>
      </b-col> -->
      <b-col cols="8" style="padding-left: 10px; text-align: left;">
        <p style="margin-bottom: 0">Login or register to book faster and get benefits.</p>
      </b-col>
      <b-col style="padding-right: 0; padding-left: 0px">
        <b-button class="logIn"><router-link to="/login">Log in</router-link></b-button>
      </b-col>
    </b-row>
    <hr>
     <b-row class="mb-5">
      <b-col cols="12">
          <span style="font-size: 18px;">{{fullNameAddress}}</span>
      </b-col>
    </b-row>
    <b-row style="margin-top: 0px;">
      <b-col style="text-align: left;">
        <h4>My details</h4>
      </b-col>
    </b-row>
    <b-form @submit="onSubmit" >
        <b-row>
            <b-col>
                <b-form-group
                    id="input-group-1"
                    label-for="input-1"
                >
                    <b-form-input
                        id="input-1"
                        v-model="form.name"
                        type="text"
                        placeholder="Name"
                        required
                    ></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group
                    id="input-group-2"
                    label-for="input-2"
                >
                    <b-form-input
                    id="input-2"
                    v-model="form.lastName"
                    type="text"
                    placeholder="Last name(s)"
                    required
                    ></b-form-input>
                </b-form-group >
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group   
                    id="input-group-4"
                    label-for="input-4"                    
                >
                    <b-form-input
                        id="input-4"
                        v-model="form.numberPlate"
                        type="text"
                        placeholder="Enter vehicle number plates"
                        required
                    ></b-form-input>
                </b-form-group>
            </b-col>    
        </b-row>

         <b-row>
            <b-col>
                <b-form-group   
                    id="input-group-4"
                    label-for="input-4"
                    description="We'll never share your email with anyone else."
                >
                    <b-form-input
                        id="input-4"
                        v-model="form.email"
                        type="email"
                        placeholder="Enter email"
                        required
                    ></b-form-input>
                </b-form-group>
            </b-col>    
        </b-row>

        <b-row>
            <b-col>
                <b-form-group id="input-group-7" v-slot="{ ariaDescribedby }">
                    <b-form-checkbox-group
                        style="text-align: left;"
                        v-model="form.checked"
                        id="checkboxes-7"
                        :aria-describedby="ariaDescribedby"
                        required
                    >
                    <b-form-checkbox class="checkBox" value="me">I accept the <b-link href="#">terms and conditions </b-link></b-form-checkbox>
                    <!-- <b-form-checkbox style="font-size: 0.75rem; letter-sapcing: 0.3px" value="that">Something else, (receive news)</b-form-checkbox> -->
                    </b-form-checkbox-group>
                </b-form-group>  
            </b-col>
        </b-row>
    <b-row class="mt-5">
        <b-col>
            <b-button type="submit" class="signUpBtn">Continue</b-button>
        </b-col>
    </b-row>
  </b-form>
  <!-- <b-row>
    <b-col>
      {{this.$store.state.displayArrivalDate}}
    </b-col>
  </b-row> -->
  </b-container> 
</template>

<script>
  export default {
    props:{
        selectedLocation: {
            type: Object
        },
        fullNameAddress: {
          type: String
        }
    },  
    data() {
      return {
        location: {},
        fullAddress: '',
        form: {
          name: '',
          lastName: '',
          email: '',
          // password: '',
          food: null,
          checked: [],
          showReservationDetails: true,
          showMap: false
        },
        foods: [{ text: 'Select One', value: null }, 'Carrots', 'Beans', 'Tomatoes', 'Corn'],
        show: true
       
      }
    },
    created() {
      this.loadData()
    },
    methods: {
      loadData() {
        this.location = this.selectedLocation;
        this.fullAddress = this.fullNameAddress;
      },
      onSubmit(event) {
        console.log('metoda onSumbit******************');
          event.preventDefault()
          // alert(JSON.stringify(this.form))
          this.$store.state.customer = this.form;
          this.$router.push('/paymentMethod');
      },
      cancel() {
        this.showReservationDetails = false;
        this.showMap = true;

        this.$emit('cancelBtn', [this.showReservationDetails, this.showMap]); 
        console.log('cancel');
      }     
    }
    
  }
</script>

<style scoped>

  .container {
    padding-left: 24px;
    padding-right: 24px;
    /* height: 100vh; */
  }

  #backBtn {
    background-color: #fff;
    border-radius: 50%;
    color: #C1001F;
    border: 1px solid #C1001F;
  }

  h4 {
    font-size: 1rem;
    margin-bottom: 0;
    color: #C1001F;
  }

  P {
    font-size: 1em; 
    color: rgba(23,24,53,.6);
  }

  input {
    padding: 14px 20px 14px 16px;
    height: 50px;
    margin: 0;
  }

  .form-group {
    margin: 0;
  }

  a {
    color: #C1001F !important;
  }

  .col {
    height: auto;
  }

  .input-group-7{
    text-align: left;
  }
  .checkBox {
    color: rgba(23,24,53,.6);
    font-size: 0.75rem; 
    letter-spacing: 0.3px;
  }

  .signUpBtn {
    width: 100%;
    height: 3em;
    border-color: #C1001F !important;
    color: white;
    background-color: #C1001F !important;
  }

  .logIn {
    color: #C1001F;
    background-color: white;
    border: 1px solid #c0c8eb;
    box-shadow: 0 3px 0 rgb(192 200 235 / 60%);
    height: 50px;
    width: 5em;
  }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #C1001F;
    outline: 0;
    border-width: 2px;
    box-shadow: 0 0 0 0.2rem rgb(193 0 31 / 1%) !important;
  }
  /* // ! RESITI */
  .custom-control-label {
    padding-top: 3px !important;
  }

</style>