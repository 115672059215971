<template>
    <b-container justify-content-center> 
        <b-row>
            <!-- //ToDo promeniti tekst  -->
            <b-col cols="12" class="main-card">
                <p id="header" style="font-size: 20px;">Reset password</p>
            </b-col>
            <b-col cols="12" style="text-align: left;">
                <p id="resetText">We will send you an email with instructions to reset your password</p>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <b-form-input v-model="email" placeholder="Email"></b-form-input>
            </b-col>
            <b-col>
                <b-button type="submit" class="signUpBtn" >Send</b-button>
            </b-col>
        </b-row>

    </b-container>
</template>

<script>
export default {
    data() {
        return {
            email: ''
        }
    }
}
</script>

<style scoped>
    .container {
        padding-left: 24px;
        padding-right: 24px;
        margin-top: 10%
    }
    .signUpBtn {
        width: 100%;
        height: 3em;
        border-color: #C1001F !important;
        color: white;
        border-radius: 6px;
        background-color: #C1001F !important;
    }

    input {
        padding: 14px 20px 14px 16px;
        height: 50px;
        margin: 0;
    }

    .form-control:focus {
        color: #495057;
        background-color: #fff;
        border-color: #C1001F;
        outline: 0;
        border-width: 2px;
        box-shadow: 0 0 0 0.2rem rgb(193 0 31 / 1%) !important;
    }

    #resetText {
        color: rgba(23,24,53,.4);
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 0;
    }

    .main-card {
        margin-top: 2em;
    }
    #header {
        font-size: 20px;
    }
</style>